<template>
  <div class="page-not-allowed">
    <div class="page-not-allowed__vertical-flex">
      <b-iconstack font-scale="5" animation="cylon">
        <b-icon
          stacked
          icon="person-fill"
          animation="throb"
          variant="info"
          scale="0.75"
        ></b-icon>
        <b-icon
          stacked
          icon="slash-circle"
          animation="spin-reverse"
          variant="danger"
        ></b-icon>
      </b-iconstack>
      <template>
        <h3 class="page-not-allowed__vertical-flex--message">
          <b>You're not supposed to be here.<br />You naughty user!</b>
        </h3>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotAllowed"
};
</script>

<style lang="scss" scoped>
.page-not-allowed {
  &__vertical-flex {
    padding-top: 18%;
    @include flex-wrap(center);
    flex-direction: column;
    @include justify-items-center;

    &--message {
      margin-top: $margin-most-used * 2;
    }
  }
}
</style>
